import type { SVGProps } from "react";

export const ChevronBottom = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_15181_9479" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect x="0.666992" y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_15181_9479)">
        <path
          d="M12.6665 15.5387L7.0127 9.88488L8.06652 8.83105L12.6665 13.4311L17.2665 8.83105L18.3203 9.88488L12.6665 15.5387Z"
          fill="#444444"
        />
      </g>
    </svg>
  );
};
