import type { SVGProps } from "react";

export const ChevronRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="chevron_right">
        <mask
          id="mask0_15317_3023"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <rect id="Bounding box" x="0.5" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_15317_3023)">
          <path
            id="chevron_right_2"
            d="M13.4457 11.9995L8.8457 7.39953L9.89953 6.3457L15.5534 11.9995L9.89953 17.6534L8.8457 16.5995L13.4457 11.9995Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};
